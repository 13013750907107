body {
	background-color: #292725;
	color: #FCFAF7;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	overflow-y: hidden;
}

.App {
	text-align: center;
	min-height: 100vh;
	align-items: center;
}

h1 {
	font-size: 57px;
}
@media only screen and (max-width: 800px) {
	body {
		overflow-y: visible;
	}
}