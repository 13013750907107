.section-container {
    text-align: left;
    padding-right: 20px;
    overflow-y: auto;
    min-height: 520px;
}

.main-heading {
    font-size: 42px;
    margin-bottom: 0;
}

.main-quote {
    font-size: 18px;
    margin-top: 0;
    font-style: italic;
    white-space: pre-wrap;
}

.section-heading-container::after {
    display: inline-block;
    content: "";
    border-top: 1px solid #e6a050;
    width: 4rem;
    margin-top: 0;
    margin-bottom: 5px;
    transform: translateY(-1rem);
}
