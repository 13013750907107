.avatar {
    width: 140px;
    padding: 4px;
    border: 2px solid #e6a050;
    border-radius: 100px;
    /* margin-bottom: 15px; */
}

@media only screen and (max-width: 600px) {
    .avatar {
        margin-top: 40px;
    }
}

.main-name {
    margin: 0;
    margin-top: 10px;
    font-size: 28px;
}

.subtitle {
    margin-top: 0;
    font-style: italic;
}

.sections-list::before,
.sections-list::after {
    display: inline-block;
    content: "";
    border-top: 1px solid #e6a050;
    width: 4rem;
    margin-top: 0;
    margin-bottom: -10px;
    transform: translateY(-1rem);
}

.section-list-element {
    font-size: 21px;
    margin: 8px;
    /* padding: 12px; */
    font-weight: 600;
}

.section-list-element:hover {
    cursor: pointer;
    font-size: 21.5px;
}