.project-card {
    font-size: 17px;
    white-space: pre-wrap;
    color: #292725;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e6a050;
    /* border: 1px solid #120136; */
    border-radius: 5px;
    padding: 20px;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;
    margin: 10px;
    font-weight: 495;
    width: 300px;
    flex-grow: 1;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.project-card > p {
    margin: 0;
}

.project-card:hover {
    transform: scale(1.05);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.images {
    height: 30vh;
}

.project-card > p > a {
    color: #292725
}

@media only screen and (max-width: 500px) {
	.images {
        height: 17vh;
        width: 75%;
	}
}
